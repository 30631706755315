import { environment as env } from 'src/environments/environment';
import { SeoSocialShareData } from '../core/model/seo-social-share.model';

export const SeoSocialShareConfig: { [key: string]: SeoSocialShareData } = {
  root: {
    title: 'EveryEng | Everything for Engineering',
    keywords:
      'Engineering,engineers,Engineering Service,Freelancing,Engineering problems,Experts,Outsourcing,Engineering life,Work life balance',
    description:
      'This Ecosystem is connecting the world of Engineering Industries, Engineering professionals & Engineering Students to provide an opportunity to Share, Learn & Earn',
    image: `${env.assets.seoSource}/root-banner.png/p.png`,
    imageAuxData: {
      width: 1024,
      height: 900,
      secureUrl: `${env.assets.seoSource}/root-banner.png/p.png`,
      mimeType: 'image/jpeg,image/png',
      alt: `${env.assets.seoSource}/root-banner.png/p.png`,
    },
    url: '',
    type: 'website',
    author: 'EveryEng',
    section: 'Internet Technology',
    published: '2021-05-27T20:15:45.000Z',
    modified: '2021-05-27T20:15:45.000Z',
  },
};
